<template>
  <div class="setting-reward">
    <CCard>
      <CCardBody>
        <div class="font-weight-bold m-1">User reward</div>
        <CRow class="reward-content">
          <CCol col="12" sm="7">
            <CRow class="custom-input" alignVertical="center">
              <CCol class sm="3">{{$t("STAKING_REWARD_PAGE_COL_COSMOS")}}</CCol>
              <CCol sm="9">
                <currency-input
                  class="input-field"
                  v-model="cosmos"
                  :precision="3"
                  :currency="{ suffix: '%' }"
                />
              </CCol>
            </CRow>
            <CRow class="custom-input" alignVertical="center">
              <CCol class sm="3">{{$t("STAKING_REWARD_PAGE_COL_IRIS")}}</CCol>
              <CCol sm="9">
                <currency-input
                  class="input-field"
                  v-model="cosmos"
                  :precision="3"
                  :currency="{ suffix: '%' }"
                />
              </CCol>
            </CRow>
            <CRow class="custom-input" alignVertical="center">
              <CCol class sm="3">{{$t("STAKING_REWARD_PAGE_COL_TEZOS")}}</CCol>
              <CCol sm="9">
                <currency-input
                  class="input-field"
                  v-model="cosmos"
                  :precision="3"
                  :currency="{ suffix: '%' }"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol
            col="12"
            sm="5"
            class="mt-2 mt-sm-0 d-block align-self-end text-right"
          >
            <CButton @click="rewardSetting" class="pl-4 pr-4" color="primary"
              >{{$t("STAKING_REWARD_PAGE_BUTTON_SAVE")}}</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="font-weight-bold m-1">{{$t("STAKING_REWARD_PAGE_TITLE_HISTORY")}}</div>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="transactions"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
          :itemsPerPage="itemsPerPage"
          :activePage="activePage"
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">
            {{ index }}
          </td>
          <td class="text-center" slot="platform" slot-scope="{ item }">
            {{ item.platform }}
          </td>
          <td class="text-center" slot="memo" slot-scope="{ item }">
            {{ item.memo }}
          </td>
          <td class="text-center" slot="updateAt" slot-scope="{ item }">
            {{ item.updateAt | moment("YYYY-MM-DD HH:mm") }}
          </td>
          <td class="text-center" slot="updateBy" slot-scope="{ item }">
            {{ item.updateBy | moment("YYYY-MM-DD HH:mm") }}
          </td>
        </CDataTable>
        <div>
          <CPagination
            :activePage.sync="activePage"
            :pages="parseInt(transactions.length / (itemsPerPage + 1)) + 1"
            align="end"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { CurrencyInput } from "vue-currency-input";
export default {
  name: "StakingReward",
  data() {
    return {
      cosmos: 0,
      iris: 0,
      tezos: 0,
      tableHeaders: [
        { key: "order", label: "#", _classes: "text-center" },
        { key: "platform", label: this.$t("STAKING_REWARD_PAGE_TABLE_COL_PLATFORM"), _classes: "text-center" },
        { key: "memo", label: this.$t("STAKING_REWARD_PAGE_TABLE_COL_MEMO"), _classes: "text-center" },
        { key: "updateAt", label: this.$t("STAKING_REWARD_PAGE_TABLE_COL_UPDATE_AT"), _classes: "text-center" },
        { key: "updateBy", label: this.$t("STAKING_REWARD_PAGE_TABLE_COL_UPDATE_BY"), _classes: "text-center" }
      ],
      transactions: [
        {
          platform: "Tezos",
          memo: "Memo 1",
          updateAt: "2019/10/10 20:30",
          updateBy: "User 1"
        },
        {
          platform: "Cosmos",
          memo: "Memo 2",
          updateAt: 1577706721,
          updateBy: "User 3"
        },
        {
          platform: "Tezos",
          memo: "Memo 3",
          updateAt: "2019/10/10 20:30",
          updateBy: "User 2"
        },
        {
          platform: "Iris",
          memo: "Memo 4",
          updateAt: "2019/10/10 20:30",
          updateBy: "User 1"
        },
        {
          platform: "Tezos",
          memo: "Memo 5",
          updateAt: "2019/10/10 20:30",
          updateBy: "User 4"
        }
      ],
      itemsPerPage: 3,
      activePage: 1
    };
  },
  methods: {
    rewardSetting() {
      console.log("cosmos: ", this.cosmos);
      console.log("iris: ", this.iris);
      console.log("tezos: ", this.tezos);
    }
  }
};
</script>
<style lang="scss">
.setting-reward /deep/ {
  .form-control {
    text-align: right;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .reward-content {
    padding: 0 8px;
  }
  .custom-input {
    padding-top: 4px;
    .input-field {
      padding: 6px 12px;
      width: 100%;
      border: 1px solid #d8dbe0;
      border-radius: 0.25rem;
      text-align: right;
    }
  }
}
</style>
